<template>
  <div class="h-100" :class="isCameraOpen && !isLoading && !isPhotoTaken ? 'bg-grey' : ''">
    
    <v-overlay :value="isLoading">
      <v-progress-circular
        :value="80"
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-center" style="padding: 4rem" v-if="userRole.toUpperCase().includes('BLACKLIST')">
      <v-row
        justify="center"
        class="null-item"
      >
        <v-col class="d-flex justify-center pb-0" cols="10">
          <v-img
            class="image"
            :src="require('../../assets/bus-homepage.svg')"
          ></v-img>
        </v-col>
        <v-col class="d-flex justify-center py-0 mt-4" cols="12">
          <h2 class="text-center">{{ $t("SuspendWarning") }}</h2>
        </v-col>
      </v-row>
    </div>
    <v-container v-else-if="upgradeStep===1" class="pt-0">
      <v-row v-if="upgradeProcess === true" justify="center" style="position: absolute;top: 46%; right: 50%;transform: translate(46%,-50%); width:100%">
        <v-col class="d-flex justify-center pb-0" cols="8">
          <v-img
            class="image"
            :src="require('../../assets/kyc/upgrade-process.png')"
          ></v-img>
        </v-col>
        <v-col class="py-0 pt-3" cols="12" align="center">
          <h3>{{$t('MembershipBeingProcessed')}}</h3>
          <span class="body-2">
            {{$t('DescribeSubmitMember1Day')}}
          </span>
        </v-col>
      </v-row>
      <div v-else-if="(upgradeProcess == false && approved == false) || rejected == true || userData.profileID == 0">
        <v-row align="center" class="pl-6 py-3" :style="`background-color: ${$partnerACL.getBackgroundColorApp()};color: ${$partnerACL.getTitleColor()}`">
          <v-col cols="4" class="pr-0">
            <img
              :src="require('../../assets/kyc/premium-page-header.svg')">
          </v-col>
          <v-col cols="8" class="white--text pl-0">
            <h3>{{$t("LetsUpgradeYourAccount")}}!</h3>
            <p class="mb-0">{{$t("EnjoyMoreBenefitsAndCompleteAccessToAllServices")}}</p>
          </v-col>
        </v-row>
        <div class="PremiumMember">
          <div class="content">
            <v-container>
              <v-row
                v-for="item in profileRequirementsList "
                :key="item.profileID"
                no-gutters align="center">
                <v-col class="12">
                  <v-card outlined class="secondary lighten-5 ma-1 rounded-card onHover" @click="clickSelectProfile(item)">
                    <v-list class="transparent">
                      <v-list-item>
                        <v-row align="center">
                          <v-col cols="4">
                          <!-- <img 
                            v-if="item.profileCode!='' && (item.profileCode=='premium'  || item.profileCode=='student')"
                            :src="require(`@/assets/kyc/${item.profileCode}.svg`)"/> -->
                          <img 
                            v-if="item.profileCode!='' && (item.profileCode=='premium'  || item.profileCode=='student' || item.profileCode=='student-card' || item.profileCode=='elderly-card' || item.profileCode=='disability-card')"
                            :src="require(`@/assets/icon-tob/${item.profileCode}.png`)"
                            height="75%" width="75%"/>
                      </v-col>
                          <v-col  cols="8">
                            <h3 class="primary--text">{{ $t(item.profileName) }}</h3>
                            <p class="caption mb-0 paragraf-truncate">{{ $t(item.profileDescription) }}</p>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12">
                  <v-card outlined class="secondary lighten-5 ma-1 mt-3 rounded-card onHover" @click="ClickReqStudent()">
                    <v-list class="transparent">
                      <v-list-item>
                        <v-row align="center">
                          <v-col cols="4">
                            <img 
                              :src="require('../../assets/kyc/user-student.svg')"/>
                          </v-col>
                          <v-col  cols="8">
                            <h3 class="primary--text">{{$t("Student")}}</h3>
                            <p class="caption mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col> -->
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
      <show-memnbership v-else-if="approved && userData.profileID != 0" :profile-info="profileInfo" :profile-status="profileStatus"/>
    </v-container>
    
    <div v-else-if="upgradeStep===2" class="div-template" >
      <notification-popup
        :dialog="showNotifParentEmailFailed"
        :headerMsg="$t('Warning')"
        :contentMsg="$t('PleaseInputValidParentEmail')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('labelYes')"
        colorIcon="warning"
        Icon="mdi-alert-circle-outline"
        typeModal="dialog"
        disableBtnNo="true"
        :onHandlerYes="buttonCloseNotifPopup"
      />

      <v-container>
        <v-row justify="center">
          <v-col cols="6">
            <img
              class="img-premium"
              :src="require('@/assets/kyc/upgrade-premium-student.svg')"/>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6" align="center" class="pa-0">
            <h4>{{$t('emailYourParents')}}</h4>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10" align="center" class="pa-0">
            <p class="mb-0">{{$t('descriptionReqStudent')}}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formRequest.parentEmail" 
              type="email"
              :label="$t('email')"
              outlined
              prepend-inner-icon="mdi-email"
              :placeholder="$t('e.g.') + ' johnparent@mail.com'"
              :append-icon="isEmailValid(formRequest.parentEmail)"
              :rules="[emailRules.isvalid]"
              name="Email"
              v-validate="'no_space|required|email|max:200'"
              :error-messages="errors.first('Email')"
              dense
              ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <!-- <v-container>
      </v-container> -->
      <div class="footer">
        <v-row justify="center">
          <v-col class="text-center" cols="4">
            <ac-button 
              :outlined="true"
              :name="$t('Back')" 
              :on-click-handler="()=>steppedBack(2)"
              :width="'100%'"
            />
          </v-col>
          <v-col class="text-center" cols="4">
            <ac-button 
              :name="selectedProfile.isHaveRequirement ? $t('Next') : $t('Submit')" 
              :is-disabled="formRequest.parentEmail==='' || errors.has('Email') || isDangerEmail(formRequest.parentEmail) || isEmailValid(formRequest.parentEmail) !== 'mdi-check'"
              :on-click-handler="parentEmailHandler"
              :width="'100%'"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    
    <div class="h-100" v-else-if="upgradeStep===3">
      <div class="h-100" v-show="isCameraOpen && !isLoading && !isPhotoTaken">
        <div class="h-100" :class="{ 'flash' : isShotPhoto }" align="center">
          <br>
          <h3>{{$t(this.selectedRequirement ? this.selectedRequirement.name : ''  )}}</h3>
          <br>
          <p class="paragraph mx-4">{{$t('MakeSureTheInformationOnItCanBeReadClearly')}}</p>
          <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
          <video v-show="!isPhotoTaken" ref="camera" class="camera-stream" width="100%" :main-height="120" autoplay>
          </video>
        </div>
        <v-container class="camera-shoot" style="width:100%;position: absolute;bottom: 0;" align="center">
          <v-row justify="center">
            <v-col cols="auto">
              <v-img 
                class="camera-img-capture"
                max-width="50px"
                max-height="50px"  
                :src="require('@/assets/kyc/buttonCamera.svg')"
                @click="takePhoto()">
              </v-img>
            </v-col>
            <v-col cols="auto mt-n5" style="top: 36%;right:5%;position: absolute;">
              <v-img
                class="camera-img-split mt-2"
                max-width="30px"
                max-height="30px"  
                :src="require('@/assets/kyc/camera-back.svg')"
                @click="changeCamera()">
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container v-show="!(isCameraOpen && !isLoading && !isPhotoTaken)" class="CompleteDataStart">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <h3> {{$t('Completeyourdata')}}</h3> 
            <p class="body-2">{{$t('Verificationprocessonlytakes1x24hours')}}</p>
          </v-col>
          <!-- <v-col cols="6">
            <v-img 
              :src="require('@/assets/kyc/completeData.png')"></v-img>
          </v-col> -->
          <!-- <v-col cols="10" align="center">
            <p>{{$t('Pleasepreparestudentidentity')}}</p>
          </v-col> -->
        </v-row>
        
        <v-row>
          <v-col cols="12" class="py-0"
            v-for="(item, index) in selectedProfileRequirements"
            :key="index"
            >
            <label class="black--text font-weight-bold">{{$t(item.name)}}</label>

            <v-text-field
              v-if="item.type === 'text'"
              v-model="formRequest.profileRequirementsInput[item.profile_requirement_id]" 
              :type="item.type"
              :label="$t(item.name)"
              :name="item.name"
              :color="$partnerACL.getButtonColor()"
              v-validate="'required|max:200'"
              :error-messages="errors.first(item.name)"
              :placeholder="$t('e.g.') + getPlaceholderText(item.name)"
              outlined
              dense
              
            ></v-text-field>
            <v-text-field
              v-else-if="item.type==='number'"
              v-model="formRequest.profileRequirementsInput[item.profile_requirement_id]" 
              :type="item.type"
              :label="$t(item.name)"
              :name="$t(item.name)"
              :color="$partnerACL.getButtonColor()"
              v-validate="getValidate(item)"
              :error-messages="errors.first($t(item.name))"
              :placeholder="$t('e.g.') + getPlaceholderText(item.name)"
              outlined
              dense
              @keypress="$helpers.typeIntegerOnPressInputHandler"
            ></v-text-field>
            <v-textarea
              v-else-if="item.type==='textarea'"
              v-model="formRequest.profileRequirementsInput[item.profile_requirement_id]" 
              outlined
              height="80"
              :placeholder="$t('e.g.') + getPlaceholderText(item.name)"
              :name="item.name"
              :color="$partnerACL.getButtonColor()"
              v-validate="'required'"
              :error-messages="errors.first(item.name)"
            ></v-textarea>
            <div
              v-else-if="item.type==='image'"
              class="mb-5 text-center"
            >
              <v-img 
                v-if="formRequest.profileRequirementsInput[item.profile_requirement_id]===''"
                width="60%" 
                :src="require(`@/assets/kyc/${((item.name).toLowerCase()).includes('selfie') ? 'selfiecardexample' : 'completeData'}.png`)" 
                class="mx-auto my-2 shadow-5 rounded-img">
              </v-img>
              <v-img 
                v-else
                width="96%" 
                :src="formRequest.profileRequirementsInput[item.profile_requirement_id]" 
                class="mx-auto my-2 shadow-5 rounded-img">
              </v-img>
              <canvas :id="`photoTaken_${item.profile_requirement_id}`" :ref="`canvas_${item.profile_requirement_id}`" height="0" class="d-none"></canvas>
              <ac-button 
                :small="true"
                :name="formRequest.profileRequirementsInput[item.profile_requirement_id]==='' ? $t('TakePhoto') : $t('RetakePhoto')" 
                :on-click-handler="()=>tookPhoto(item)"
                :width="'40%'"
                class-btn="mx-auto"
              />
            </div>
            <div
              v-else-if="item.type==='dropdown'"
              class="mb-5 text-center"
            >
            <dropdown-membership 
              :change-select-item="changeSelectItem" 
              :form-param="String(item.profile_requirement_id)"
              :change-input="changeOthersInput"
            />
            </div>
          </v-col>
        </v-row>
        <div class="footer">
          <v-row justify="center">
            <v-col class="text-center" cols="4">
              <ac-button 
                :outlined="true"
                :name="$t('Back')" 
                :on-click-handler="()=>steppedBack(3)"
                :width="'100%'"
              />
            </v-col>
            <v-col class="text-center" cols="4">
              <ac-button 
                :name="$t('Submit')" 
                :is-disabled="isDisableSubmit"
                :on-click-handler="submitMembership"
                :width="'100%'"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    
      <notification-popup 
      :dialog="showConfirmationPopup"
      :contentMsg="$t('DescribeSubmitSuccessKyc1Day')"
      :headerMsg="$t('SubmitMembership')"
      colorheaderMsg="#4BB14E"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      :onHandlerNo="cancelSubmit"
      :onHandlerYes="confirmSubmit"/>
      
      <notification-popup
        :dialog="showIncompleteInputPopup"
        :headerMsg="$t('IncompleteData')"
        :contentMsg="$t('PleaseCompleteYourData')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('labelYes')"
        colorIcon="warning"
        Icon="mdi-alert-circle-outline"
        typeModal="dialog"
        disableBtnNo="true"
        :onHandlerYes="buttonCloseNotifPopup"
      />
      
      <notification-popup 
        :dialog="showNotifFailed"
        :contentMsg="$t('failed')"
        :headerMsg="$t('FailedSubmit')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('labelYes')"
        colorIcon = "danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        :onHandlerYes="buttonCloseNotifPopup"/>

      <notification-popup  
      :dialog="showNotifSuccess"
      :contentMsg="$t('DescribeSubmitSuccessKyc1Day')"
      :headerMsg="$t('Success')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('BacktoHome')"
      colorIcon = "success"
      typeModal="transaction"
      Icon="mdi-check-circle"
      disableBtnNo="true"
      :onHandlerYes="successDone"/>
  </div>
</template>

<script>
// @ is an alias to /src
import NotificationPopup from "@/components/NotificationPopup.vue";
import DropdownMembership from "@/components/DropdownMembership.vue";
import AcButton from "@/components/AcButton.vue";
import ShowMemnbership from "./ShowMembership.vue";
import { Validator } from 'vee-validate';
import moment from 'moment'

export default {
  name: "PremiumMember",
  components: {
    NotificationPopup,
    ShowMemnbership,
    AcButton,
    DropdownMembership
  },
  data() {
    return {
      // rules: {
      //     required: (value) => !!value || "Required.",
      //     min: (v) => v.length >= 6 || "Min 6 characters",
      //     diffEmail: (v) => v !== this.userData.email || this.$t("Parent email can't be same as your email."),
      //     emailMatch: () => `The email and password you entered don't match`,
      //   },
      upgradeStep: 1,
      upgradeProcess: false,
      alreadyReviewed: false,
      formRequest: {
        parentEmail: "",
        profileRequirementsInput: {}
      },

      profileRequirementsList: [],
      selectedProfile: null,
      selectedProfileRequirements: [],
      selectedRequirement: null,

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      showNotifParentEmailFailed: false,
      showConfirmationPopup: false,
      showIncompleteInputPopup: false,
      showNotifFailed: false,
      showNotifSuccess: false,
      filesToUpload: [],
      payload: null,
      approved: false,
      rejected: false,
      profileInfo: null,
      profileStatus: null,
      isDisableSubmit: false,
      emailRules: {
        isvalid: value => {
          const isvalid = this.isEmailValid(value)
          if (isvalid === 'mdi-alert-circle-outline') {
            return this.$t("ParentEmailRules")
          }
          return ""
        }
      },
      cameraPosition: '',
      streamCamera: null
    };
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData;
    },
    userRole() {
      return this.$store.state.auth.user_role;
    }
  },
  async created() {

    Validator.extend('no_space', {
      getMessage: field => this.$t("SpaceErrorMessage", { field: field }),
      validate: value => !(/\s/g.test(value))
    });

    Validator.extend('required', {
      getMessage: field => this.$t("RequiredErrorMessage", { field: this.$t(field) }),
      validate: value => value != ''
    });

    Validator.extend('max', {
      paramNames: ['max'],
      getMessage: (field, [max]) => this.$t("RequiredMax", { field: this.$t(field), max }),
      validate: (value, { max }) => String(value).length <= max
    });

    const payload = {
      customerID: this.userData.customerID,
      profileID: this.userData.profileID
    }

    await this.$store
      .dispatch("customer/profileChecking", payload)
      .then((response) => {
        this.profileStatus = response;
        if (response.requestStatus === '') {
          this.upgradeProcess = false;
          this.alreadyReviewed = false;
        } else if (response.requestStatus === 'REQUESTED') {
          this.upgradeProcess = true;
          this.alreadyReviewed = false;
        } else if (response.requestStatus === 'APPROVED') {
          this.upgradeProcess = false;
          this.alreadyReviewed = true;
          this.approved = true;
        } else if (response.requestStatus === 'REJECTED') {
          this.upgradeProcess = false;
          this.alreadyReviewed = true;
          this.rejected = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (this.alreadyReviewed && this.approved && this.userData.profileID != 0) {
      await this.$store
        .dispatch("customer/getProfileDetail", this.userData.profileID)
        .then((response) => {
          this.profileInfo = response;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    await this.$store
      .dispatch("customer/getProfileRequirements")
      .then((response) => {
        this.profileRequirementsList = response;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  updated() {
    this.disableSubmit()
  },
  methods: {
    disableSubmit() {
      const submitStat = Object.keys(this.formRequest.profileRequirementsInput).filter(input => this.formRequest.profileRequirementsInput[input] === '').length > 0
      this.isDisableSubmit = submitStat
    },
    getPlaceholderText(itemName) {
      if (itemName.toLowerCase().includes("address")) {
        return " 101 Anywhere Dr, Apt.34, Mailburg VA 22005";
      } else if (itemName.toLowerCase().includes("email")) {
        return " user@mail.com"
      } else if (itemName.toLowerCase().includes("occupation")) {
        return " Teacher"
      } else if (["student", "id", "card", "number"].every(str => itemName.toLowerCase().includes(str))) {
        return " 017524202"
      } else if (["id", "card", "number"].every(str => itemName.toLowerCase().includes(str))) {
        return " 729250703"
      } else if (itemName.toLowerCase().includes("mother name")) {
        return " Hani"
      } else if (itemName.toLowerCase().includes("number")) {
        return " 1234567890"
      } else if (itemName.toLowerCase().includes("pan")) {
        return " 6032 9850 00618093"
      }
      // else if (['number', 'pan'].map((item, _) => {
      //   const splitText = itemName.split(' ')
      //   splitText.map((split, __) => {
      //     return split.toLowerCase().includes(item.toLowerCase())
      //   })
      // })) {
      //   return ' 1234567890'
      // }
      return "";
    },
    getValidate(item) {
      if (item.type == 'number') {
        if (item.name == 'NISN Number') {
          return 'required|numeric|max:10'
        } else if (item.name == 'PAN' || item.name == 'ID Card Number') {
          return 'required|numeric|max:16'
        }
      }
      return 'required|numeric|max:25'
    },
    steppedBack(currentStep) {
      if (currentStep == 3) {
        if (this.selectedProfile.isLinkedWithParent) {
          this.upgradeStep = 2;
        } else {
          this.steppedBack(2);
        }
      } else if (currentStep == 2) {
        this.upgradeStep = 1;
        this.formRequest = {
          parentEmail: "",
          profileRequirementsInput: {}
        }
      }
    },
    clickSelectProfile(profile) {
      this.selectedProfile = profile;
      this.selectedProfileRequirements = JSON.parse(this.selectedProfile.profileRequirements)[0];
      if (this.selectedProfile.isLinkedWithParent) {
        this.upgradeStep = 2;
      } else {
        this.goToCompleteDataRequirement();
      }
    },
    /*
      step 2 methods
    */
    async parentEmailHandler() {
      await this.$store
        .dispatch("customer/checkParentEmail", this.formRequest.parentEmail)
        .then((response) => {
          if (response.status == true) {
            this.showNotifParentEmailFailed = false;
            this.goToCompleteDataRequirement();
          } else {
            this.showNotifParentEmailFailed = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    /*
      step 3 methods
    */
    goToCompleteDataRequirement() {
      if (this.selectedProfile.isHaveRequirement) {
        this.selectedProfileRequirements.forEach(req => {
          this.formRequest.profileRequirementsInput[req.profile_requirement_id] = this.formRequest.profileRequirementsInput[req.profile_requirement_id] ? this.formRequest.profileRequirementsInput[req.profile_requirement_id] : ''
        });
        this.upgradeStep = 3;
      } else {
        // this.upgradeStep = 4;
        this.showConfirmationPopup = true;
      }
    },
    buttonCloseNotifPopup() {
      this.showNotifParentEmailFailed = false;
      this.showConfirmationPopup = false;
      this.showIncompleteInputPopup = false;
      this.showNotifFailed = false;
      this.showNotifSuccess = false;
    },
    tookPhoto(req) {
      this.selectedRequirement = req;
      this.toggleCamera();
    },
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.cameraPosition = 'back'
        // front camera setting
        const constraint = {
          audio: false,
          video: {
            facingMode: 'environment'
          }
        }
        this.createCameraElement(constraint);
      }
    },

    createCameraElement(constraint) {
      this.isLoading = true;

      const constraints = (window.constraints = constraint);
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.streamCamera = stream
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs['canvas_' + this.selectedRequirement.profile_requirement_id][0].getContext('2d');
      context.canvas.width = (window.innerWidth);
      context.canvas.height = (context.canvas.width / 3) * 2;
      context.drawImage(this.$refs.camera, 0, 0, (window.innerWidth), (context.canvas.width / 3) * 2);
      this.toggleCamera();
      this.formRequest.profileRequirementsInput[this.selectedRequirement.profile_requirement_id] = this.$refs['canvas_' + this.selectedRequirement.profile_requirement_id][0].toDataURL();
      this.selectedRequirement = null;
    },
    changeCamera() {
      let constraint = {}
      if (this.cameraPosition === 'front') {
        // front camera setting
        constraint = {
          audio: false,
          video: {
            facingMode: 'environment'
          }
        }
        this.cameraPosition = 'back'
      } else if (this.cameraPosition === 'back') {
        constraint = {
          audio: false,
          video: {
            facingMode: 'user'
          }
        }
        this.cameraPosition = 'front'
      }
      this.streamCamera.getTracks().forEach(function(track) {
        track.stop();
      });
      this.streamCamera = null
      this.createCameraElement(constraint)
    },
    async submitMembership() {
      const arrayOfFormEmptyInputField = Object.keys(this.formRequest.profileRequirementsInput).filter(input => this.formRequest.profileRequirementsInput[input] === '');
      if (arrayOfFormEmptyInputField.length > 0) {
        this.showIncompleteInputPopup = true;
      } else {
        this.showConfirmationPopup = true;
      }
    },
    async reduceImageSize(base64Str, MAX_WIDTH = 600, MAX_HEIGHT = 600) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
          let canvas = document.createElement('canvas')
          let width = img.width
          let height = img.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL()) // this will return base64 image results after resize
        }
      });
      return resized_base64;
    },
    calcImageSize(image) {
      let y = 1;
      if (image.endsWith('==')) {
          y = 2
      }
      const x_size = (image.length * (3 / 4)) - y
      return Math.round(x_size / 1024)
    },
    async processImage(file, maxImageSize = 850) {
      const res = file
      const old_size = this.calcImageSize(res);
      if (old_size > maxImageSize) {
        const resized = await this.reduceImageSize(res);
        return resized;
      } else {
        // Image already small enough
        return res;
      }
    },
    async b64toBlob(key, imgStr, sliceSize, identityType, identityName) {
      const resizeBase64 = await this.processImage(imgStr)
      var block = resizeBase64.split(";");
      var contentType = block[0].split(":")[1];
      var b64Data = block[1].split(",")[1];

      contentType = contentType || '';
      sliceSize = sliceSize || 100;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      const fileName = this.userData.customerID + "_" + key + ".jpg";

      this.filesToUpload.push({
        key: key,
        identityType: identityType,
        identityName: identityName,
        file: blob,
        fileName: fileName
      })
      return fileName;
    },
    cancelSubmit() {
      this.showConfirmationPopup = false;
    },
    async confirmSubmit() {
      this.showConfirmationPopup = false;
      this.isLoading = true;

      // const formKeys = Object.keys(this.formRequest.profileRequirementsInput).map(req => req)

      this.payload = {
        username: this.userData.username,
        currentProfileID: this.userData.profileID,
        requestedProfileID: parseInt(this.selectedProfile.profileID),
        customerID: this.userData.customerID,
        customerUserID: this.userData.customerUserID,
        parentEmail: this.formRequest.parentEmail,
        address: this.formRequest.profileRequirementsInput[1] || "",
        occupation: this.formRequest.profileRequirementsInput[2] || "",
        identityFile: [],
        expiredYear: null
      }
      if (this.selectedProfile.profileCode == 'student-card') {
        this.payload.identityFile.push({
          identityType: 'student_card',
          identityNumber: this.formRequest.profileRequirementsInput[11] != undefined ? this.formRequest.profileRequirementsInput[11] : this.formRequest.profileRequirementsInput[21] != undefined ? this.formRequest.profileRequirementsInput[21] : "",
          identityPhoto: this.formRequest.profileRequirementsInput[22] ? this.b64toBlob(22, this.formRequest.profileRequirementsInput[22], 100, 'student_card', 'identityPhoto') : "",
          identityPhotoSelfie: this.formRequest.profileRequirementsInput[23] ? this.b64toBlob(23, this.formRequest.profileRequirementsInput[23], 100, 'student_card', 'identityPhotoSelfie') : "",
          identityPanNumber: this.formRequest.profileRequirementsInput[24] || "",
          identityPanPhoto: this.formRequest.profileRequirementsInput[27] ? this.b64toBlob(27, this.formRequest.profileRequirementsInput[27], 100, 'student_card', 'identityPanPhoto') : "",
          identitySchoolName: this.formRequest.profileRequirementsInput[25] || "",
          identitySchoolGrade: this.formRequest.profileRequirementsInput[26] || "",
          identityBiologicalMother: this.formRequest.profileRequirementsInput[28] || "",
          identityCity: this.formRequest.profileRequirementsInput[30] || "",
          identityIssuer: this.formRequest.profileRequirementsInput[31] || "",
          identityClass: this.formRequest.profileRequirementsInput[32] || "",
          identityNISNNumber: this.formRequest.profileRequirementsInput[34] || "",
          identityPaymentChannel: this.formRequest.profileRequirementsInput[35] || "",
        })

        const classGrade = this.formRequest.profileRequirementsInput[32] || ""
        const schoolGrade = this.formRequest.profileRequirementsInput[26] || ""

        if (schoolGrade == 'SD') {
          this.payload.expiredYear = moment().add(7 - Number(classGrade), 'years').format('YYYY-MM-DD') + ' 23:59:59.999'
        } else if (schoolGrade == 'SMP') {
          this.payload.expiredYear = moment().add(10 - Number(classGrade), 'years').format('YYYY-MM-DD') + ' 23:59:59.999'
        } else if (schoolGrade == 'SMA' || schoolGrade == 'SMK') {
          this.payload.expiredYear = moment().add(13 - Number(classGrade), 'years').format('YYYY-MM-DD') + ' 23:59:59.999'
        } else if (schoolGrade == 'MAHASISWA') {
          const isOdd = classGrade % 2 == 0 ? 0 : 0.5
          const formula = (((12 - classGrade) / 2) - isOdd) + 1
          this.payload.expiredYear = moment().add(formula, 'years').format('YYYY-MM-DD') + ' 23:59:59.999'
        }

      } else if (this.selectedProfile.profileCode == 'disability-card') {
        this.payload.identityFile.push({
          identityType: 'disability_card',
          identityNumber: this.formRequest.profileRequirementsInput[11] != undefined ? this.formRequest.profileRequirementsInput[11] : this.formRequest.profileRequirementsInput[21] != undefined ? this.formRequest.profileRequirementsInput[21] : "",
          identityPhoto: this.formRequest.profileRequirementsInput[12] ? this.b64toBlob(12, this.formRequest.profileRequirementsInput[12], 100, 'disability_card', 'identityPhoto') : "",
          identityPhotoSelfie: "",
          identityPanNumber: this.formRequest.profileRequirementsInput[24] || "",
          identityPanPhoto: this.formRequest.profileRequirementsInput[27] ? this.b64toBlob(27, this.formRequest.profileRequirementsInput[27], 100, 'disability_card', 'identityPanPhoto') : "",
          identityBiologicalMother: this.formRequest.profileRequirementsInput[28] || "",
          identityDisabilityType: this.formRequest.profileRequirementsInput[29] || "",
          identityCity: this.formRequest.profileRequirementsInput[30] || "",
          identityIssuer: this.formRequest.profileRequirementsInput[31] || "",
          identityFamilyCardPhoto: this.formRequest.profileRequirementsInput[33] ? this.b64toBlob(12, this.formRequest.profileRequirementsInput[33], 100, 'disability_card', 'identityFamilyCardPhoto') : "",
          identityPaymentChannel: this.formRequest.profileRequirementsInput[35] || "",
        })
      } else if (this.selectedProfile.profileCode == 'elderly-card') {
        this.payload.identityFile.push({
          identityType: 'elderly_card',
          identityNumber: this.formRequest.profileRequirementsInput[11] != undefined ? this.formRequest.profileRequirementsInput[11] : this.formRequest.profileRequirementsInput[21] != undefined ? this.formRequest.profileRequirementsInput[21] : "",
          identityPhoto: this.formRequest.profileRequirementsInput[12] ? this.b64toBlob(12, this.formRequest.profileRequirementsInput[12], 100, 'elderly_card', 'identityPhoto') : "",
          identityPhotoSelfie: "",
          identityPanNumber: this.formRequest.profileRequirementsInput[24] || "",
          identityPanPhoto: this.formRequest.profileRequirementsInput[27] ? this.b64toBlob(27, this.formRequest.profileRequirementsInput[27], 100, 'elderly_card', 'identityPanPhoto') : "",
          identityBiologicalMother: this.formRequest.profileRequirementsInput[28] || "",
          identityCity: this.formRequest.profileRequirementsInput[30] || "",
          identityIssuer: this.formRequest.profileRequirementsInput[31] || "",
          identityFamilyCardPhoto: this.formRequest.profileRequirementsInput[33] ? this.b64toBlob(12, this.formRequest.profileRequirementsInput[33], 100, 'elderly_card', 'identityFamilyCardPhoto') : "",
          identityPaymentChannel: this.formRequest.profileRequirementsInput[35] || "",
        })
      }

      // if (formKeys.find(fkey => fkey == 11 || fkey == 12 || fkey == 13)) {
      //   this.payload.identityFile.push({
      //     identityType: 'identity_card',
      //     identityNumber: this.formRequest.profileRequirementsInput[11] || "",
      //     identityPhoto: this.formRequest.profileRequirementsInput[12] ? this.b64toBlob(12, this.formRequest.profileRequirementsInput[12], 100, 'identity_card', 'identityPhoto') : "",
      //     identityPhotoSelfie: this.formRequest.profileRequirementsInput[13] ? this.b64toBlob(13, this.formRequest.profileRequirementsInput[13], 100, 'identity_card', 'identityPhotoSelfie') : "",
      //     identityPANNumber: this.formRequest.profileRequirementsInput[24] || "",
      //     identityPANPhoto: this.formRequest.profileRequirementsInput[27] || "",
      //   })
      // }
      // if (formKeys.find(fkey => fkey == 21 || fkey == 22 || fkey == 23)) {
      //   this.payload.identityFile.push({
      //     identityType: 'student_card',
      //     identityNumber: this.formRequest.profileRequirementsInput[21] || "",
      //     identityPhoto: this.formRequest.profileRequirementsInput[22] ? this.b64toBlob(22, this.formRequest.profileRequirementsInput[22], 100, 'student_card', 'identityPhoto') : "",
      //     identityPhotoSelfie: this.formRequest.profileRequirementsInput[23] ? this.b64toBlob(23, this.formRequest.profileRequirementsInput[23], 100, 'student_card', 'identityPhotoSelfie') : "",
      //     identitySchoolName: this.formRequest.profileRequirementsInput[25] || "",
      //     identitySchoolGrade: this.formRequest.profileRequirementsInput[26] || "",
      //     identityPANNumber: this.formRequest.profileRequirementsInput[24] || "",
      //     identityPANPhoto: this.formRequest.profileRequirementsInput[27] || "",
      //     identityBiologicalMother: this.formRequest.profileRequirementsInput[28] || "",
      //   })
      // }

      if (this.filesToUpload.length > 0) {
        this.submitData()
      } else {
        setTimeout(() => {
          this.submitData()
        }, 1000);
      }
    },
    async sendUploadFiles(value) {
      return new Promise((resolve, reject) => {
        const filePayload = {
          file: value.file,
          filename: value.fileName,
        }
        this.$store
          .dispatch("cdn/uploadPhotoIdentity", filePayload)
          .then((response) => {
            if (response.status == true) {
              this.payload.identityFile.find(id_file => id_file.identityType == value.identityType)[value.identityName] = response.photo;
            }
            resolve(response);
          })
          .catch((err) => {
            console.log(err);
            // this.showNotifFailed = true;
            reject(err);
          });
      });
    },
    async submitData() {
      const promises = [];
      await this.filesToUpload.forEach(async (value) => {
        promises.push(this.sendUploadFiles(value));
      })

      Promise.all(promises)
        .then(async () => {
          await this.$store
            .dispatch("customer/membershipRequest", this.payload)
            .then((response) => {
              // this.notifSuccess = true;
              if (response.status) {
                this.showNotifSuccess = true;
              } else {
                this.showNotifFailed = true;
              }
            })
            .catch((err) => {
              console.log(err);
              // this.notifFailed = true;
              throw err;
            });
          this.isLoading = false;
        })
        .catch((e) => {
          // Handle errors here
          console.log(e)
          this.isLoading = false;
          // this.showNotifFailed = true;
        });
    },
    successDone() {
      this.showNotifSuccess = false;
      this.$router.push({
        name: 'Home',
      })
    },
    isEmailValid(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let data = ""
      if ((email.length > 0) && (re.test(email) == true) && email !== this.userData.email) {
        data = 'mdi-check';
      }
      else if (((email.length > 0) && (re.test(email) == false)) || email === this.userData.email) {
        data = 'mdi-alert-circle-outline';
      }
      return data
    },
    isSuccessEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let data = false
      if ((email.length > 0) && (re.test(email) == true)) {
        data = false;
      }
      else if ((email.length > 0) && (re.test(email) == false)) {
        data = true;
      }

      return data;
    },
    isDangerEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let data = false
      if ((email.length > 0) && (re.test(email) == true)) {
        data = false;
      }
      else if ((email.length > 0) && (re.test(email) == false)) {
        data = true;
      }

      return data;
    },
    changeSelectItem(payload) {
      this.formRequest.profileRequirementsInput[payload.params] = payload.data
      if (payload.params == 25) {
        const data = {
          school_name: this.formRequest.profileRequirementsInput[25]
        }
        this.$root.$emit('dropdown-membership-change-25', data)
      } else if (payload.params == 26) {
        const data = {
          school_grade: payload.data,
          city_name: this.formRequest.profileRequirementsInput[30]
        }
        this.$root.$emit('dropdown-membership-change-26', data)
      } else if (payload.params == 30) {
        const data = {
          profile_code: this.selectedProfile.profileCode
        }
        this.$root.$emit('dropdown-membership-change-30', data)
      }
      this.disableSubmit()
    },
    changeOthersInput(payload) {
      this.formRequest.profileRequirementsInput[payload.params] = 'OTHERS - ' + payload.data
    }
  },

};
</script>

<style lang="scss" scoped>
.paragraf-truncate {
  overflow: hidden;
  max-height: 8rem;
  max-width: 60vw;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}

.h-100 {
  height: 100%;
}

.shadow-5 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.rounded-img {
  // background-color: transparent!important;
  border-radius: 16px;
}

.bg-grey {
  position: flex;
  display: block;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  background-color: #646464;
  color: #ffffff;
}

.background {
  background: #479D4A;
  background-image: url(/img/header-decorations.1a1215dc.svg), linear-gradient(180deg, #479D4A 0%, #5DB960 100%);
  background-position: right bottom;
  background-repeat: no-repeat;
}

.onHover {
  cursor: pointer;
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {

  .rounded-card {
    border-radius: 15px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  }

  .content {
    z-index: 50%;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .rounded-card {
    border-radius: 15px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  }

  .content {
    z-index: 50%;
  }
}
</style>
