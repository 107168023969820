<template>
    <div>
        <v-autocomplete
            append-icon="mdi-chevron-down"
            :placeholder="$t('SelectData')"
            v-bind:items="listDropdown"
            v-model="itemSelected"
            item-text="name"
            item-value="code"
            :color="$partnerACL.getButtonColor()"
            outlined
            class="pt-1"
            dense
            @change="changeItem(itemSelected)"
            >
            <template slot="item" slot-scope="data">
                {{ data.item.name}}
            </template>
        </v-autocomplete>
        <v-text-field
            v-if="showOther"
            type="text"
            v-model="itemInput"
            :label="$t('LabelOthersData')"
            :name="$t('OthersData')"
            :color="$partnerACL.getButtonColor()"
            v-validate="'required|max:200'"
            :error-messages="errors.first($t('OthersData'))"
            outlined
            dense

        ></v-text-field>
    </div>
</template>

<script>
import store from "@/store";

export default {
    name: "DropdownMembership",
    props: {
        changeSelectItem: {
            type: Function,
            require: true,
            default: () => { }
        },
        formParam: {
            type: String,
            require: true,
            default: ""
        },
        changeInput: {
            type: Function,
            require: false,
            default: () => { }
        }
    },
    data() {
        return {
            itemSelected: "",
            listDropdown: [],
            showOther: false,
            itemInput: ""
        }
    },
    watch:{
        itemInput() {
            const payload = {
                params: this.formParam,
                data: this.itemInput
            }
            this.changeInput(payload)
        }
    },
    methods: {
        changeItem(item) {
            const split = item.split('||')
            const payload = {
                id: Number(split[0]),
                data: split[1],
                params: this.formParam
            }
            this.changeSelectItem(payload)
        },
        async checkItem() {
            if (this.formParam == '25') {
                this.listDropdown = []
            } else if (this.formParam == '26') {
                this.listDropdown = []
            } else if (this.formParam == '32') {
                this.listDropdown = []
            } else {
                await this.getItemData(this.formParam)
            }
        },
        async getItemData(param, city = '', grade = '', list = []) {
            const payload = {
                dropdown_id: param,
                partner_id: store.state.auth.userData.partnerID,
                city_name: city,
                school_grade: grade
            }
            await this.$store
                .dispatch("customer/listDropdownMembership", payload)
                .then((response) => {
                    if (response.status) {
                        for (let data of response.data) {
                            const item = {
                                code: data.id + '||' + data.name,
                                name: data.name
                            }
                            list.push(item)
                        }
                        this.listDropdown = list
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        this.checkItem()
        this.$root.$on("dropdown-membership-change-25", async (callback) => {
            if (this.formParam == '25') {
                this.itemInput = ""
                this.showOther = callback.school_name == String(this.$t('OthersData')).toUpperCase()
            }
        });
        this.$root.$on("dropdown-membership-change-26", async (callback) => {
            if (this.formParam == '25') {
                this.itemSelected = ""
                const list = []
                const item = {
                    code: 'others' + '||' + String(this.$t('OthersData')).toUpperCase(),
                    name: String(this.$t('OthersData')).toUpperCase()
                }
                list.push(item)
                this.listDropdown = list
                await this.getItemData('25', callback.city_name, callback.school_grade, list)
            } else if (this.formParam == '32') {
                this.itemSelected = ""
                await this.getItemData('32', callback.city_name, callback.school_grade)
            }
        });
        this.$root.$on("dropdown-membership-change-30", async (callback) => {

            if (this.formParam == '26' && callback.profile_code == 'student-card') {
                this.itemSelected = ""
                if (this.listDropdown.length == 0) {
                    await this.getItemData('26')
                }
            } else if (this.formParam == '25' || this.formParam == '32') {
                this.itemSelected = ""
                this.listDropdown = []
            }
        });
    },
}
</script>