<template>
  <div class="PremiumMembership">
    <notification-popup 
      :dialog="showDialogCancelMembership"
      :contentMsg="$t('DescbribeCancel')"
      :headerMsg="$t('CancelMembership')"
      colorheaderMsg="error--text"
      nameBtnNo="No"
      nameBtnYes="Yes"
      colorIcon = "danger"
      :onHandlerNo="clickNoCancelMembership"
      :onHandlerYes="clickYesCancelMembership"/>
      
      <notification-popup  
      :dialog="showNotifSuccess"
      :contentMsg="$t('SuccessCancelMembership')"
      :headerMsg="$t('Success')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('BacktoHome')"
      colorIcon = "success"
      typeModal="transaction"
      Icon="mdi-check-circle"
      disableBtnNo="true"
      :onHandlerYes="successDone"/>
      
      <notification-popup 
        :dialog="showNotifFailed"
        :contentMsg="$t('failed')"
        :headerMsg="$t('FailedSubmit')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('labelYes')"
        colorIcon = "danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        :onHandlerYes="()=>showNotifFailed=false"/>

    <div class="item-add">
      <div align="center">
          <img
          class="img-premium"
          :src="require('@/assets/profile/premium-membership.svg')"/>
          <p class="title-student-membership">{{$t('YouAreA')}} {{$t(profileInfo ? profileInfo.profileName : '')}} {{$t('Membership')}}</p>
          <p class="describe-membership">{{$t('describeMembership')}}</p>
      </div>
      <v-container>
        <v-row align="center" class="px-5 border-bottom pb-1">
          <v-col cols="2" class="pa-2">
            <v-img
              max-height="150"
              max-width="250"
              :src="require('@/assets/profile/list.svg')"
            ></v-img>
          </v-col>
          <v-col cols="10">
            <p class="title-detail">{{$t('titlePremiumMembership1')}}</p>
                <p class="describe-detail mb-0">{{$t('describePremiumMembership1')}}</p>
          </v-col>
        </v-row>
        
        <v-row align="center" class="px-5 border-bottom py-1">
          <v-col cols="2" class="pa-2">
            <v-img
              :src="require('@/assets/profile/checklist.svg')"
            ></v-img>
          </v-col>
          <v-col cols="10">
            <p class="title-detail">{{$t('titlePremiumMembership2')}}</p>
                <p class="describe-detail mb-0">{{$t('describePremiumMembership2')}}</p>
          </v-col>
        </v-row>
        
        <v-row align="center" class="px-5 border-bottom py-1">
          <v-col cols="2" class="pa-2">
            <v-img
              :src="require('@/assets/profile/checklist.svg')"
            ></v-img>
          </v-col>
          <v-col cols="10">
            <p class="title-detail">{{$t('titlePremiumMembership3')}}</p>
                <p class="describe-detail mb-0">{{$t('describePremiumMembership3')}}</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="footer">
      <v-row>
        <v-col class="text-center">
          <v-btn
            outlined
            rounded
            color="primary"
            width="80%"
            @click="clickCancelMembership()"
          >
            {{ $t("CancelMembership") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";

export default {
  name: "ShowMemnbership",
  components: {
    NotificationPopup
  },
  props: {
    profileInfo: {
      type: Object,
      default: ()=>{}
    },
    profileStatus: {
      type: Object,
      default: ()=>{}
    }
  },
  data() {
    return {
      showDialogCancelMembership:false,
      showNotifSuccess: false,
      showNotifFailed: false,
    };
  },
  computed : {
    userData() {
      return this.$store.state.auth.userData;
    }
  },
  methods: {
    async successDone() {
      this.showNotifSuccess = false;
      await this.$store.dispatch("auth/refreshTokenReq")
      this.$router.push({
        name: 'Home',
      })
    },
    clickCancelMembership(){
      this.showDialogCancelMembership = true;
    },
    async clickYesCancelMembership(){
      this.showDialogCancelMembership = false;
      const payload = {
        customerProfileRequestID: this.profileStatus.customerProfileRequestID,
        customerProfileID: this.profileStatus.customerProfileID,
        customerUserID: this.userData.customerUserID,
      }
      await this.$store
        .dispatch("customer/membershipCancel", payload)
        .then(() => {
          this.showNotifSuccess = true;
        })
        .catch((err) => {
          // this.showNotifFailed = true;
          console.log(err);
          throw err;
        });
    },
    clickNoCancelMembership(){
        this.showDialogCancelMembership = false;
        // this action delete membership
    },
    // ClickReqStudent(){
    //   if(this.$store.state.auth.user_role == 'PASSENGER-BASIC' ){
    //     this.$router.push({
    //       name: 'ReqStudent',
    //     })
    //   }else if(this.$store.state.auth.user_role == 'PASSENGER-STUDENT' ){
    //     this.$router.push({
    //       name: 'PremiumMember',
    //     })
    //   }else{
    //     this.$router.push({
    //       name: 'PremiumMember',
    //     })
    //   }
    // }
  },
};
</script>


<style lang="scss" scoped>
  .PremiumMembership {
    background: #fff;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    background: #ffffff;
    mix-blend-mode: normal;
  }
  /* If the screen size is 601px or more, set the font-size of <div> to 80px */
  @media only screen and (min-width: 601px) {
    .onHover{
      cursor: pointer;
    }
  
    .rounded-card {
      border-radius: 15px !important;
      background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    }
  
  .image-head{
      justify-content: center;
      width: 50%;
      height: 30%;
      margin-top: 50px;
      margin-bottom: 30px;
    }
    .img-premium{
      margin-top: -40%;
      width: 100%;
      height: 35%;
    }
    .content{
      z-index: 50%;
    }
  
  .describe-null-active{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    align-items: center;
    text-align: center;
  
    color: #868686;
  }
  
  .title-no-promotion{
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      align-items: center;
      text-align: center;
  }
  
  .title-student-membership{
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
  }
  
    .img-add{
      height: auto;
      width: 50px;
    }
  .describe-membership{
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
  }
  .title-detail{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
  }
  
      .describe-detail{
          margin-top:-10px;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
      }
  
    .btn-add{
      position: fixed;
      left: 10%;
      bottom: 0;
      width: 80%;
      margin-bottom: 10%;
  
      text-transform: capitalize;
    }
  }
  
  /* If the screen max size is 600px, set the font-size of <div> to 80px */
  @media only screen and (max-width: 600px) {
    .onHover{
      cursor: pointer;
    }
  
    .rounded-card {
      border-radius: 15px !important;
      background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    }
  
  
  .describe-null-active{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
  
    color: #868686;
  }
  
  .title-no-promotion{
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      align-items: center;
      text-align: center;
  }
  .image-head{
      justify-content: center;
      width: 70%;
      height: 50%;
      margin-top: 100px;
      margin-bottom: 30px;
    }
  
    .img-premium{
      margin-top: -40%;
      width: 100%;
      height: 50%;
    }
    .content{
      z-index: 50%;
    }
      .title-student-membership{
          width: 259px;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
      }
  
      .describe-membership{
          width: 193px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
      }
  
    .img-add{
      margin-top:7%;
      width: 80px;
      height: auto;
    }
  
      .title-detail{
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
      }
      .describe-detail{
          margin-top:-10px;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
      }
  
    .btn-add{
      position: fixed;
      left: 10%;
      bottom: 0;
      width: 80%;
      margin-bottom: 10%;
  
      text-transform: capitalize;
    }
  }
  </style>